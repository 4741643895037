<template>
  <standard-layout>
    <template v-slot:heading>
      <h1>Alles, was Du über ein Erlebnis auf dem SPABoot wissen möchtest</h1>
    </template>
    <template v-slot:main>
      <div class="category">
        <h2>Reservieren und Buchen</h2>
        <div class="question-section"
             id="children">
          <div class="question">Was ist mit Kindern?</div>
          <div class="answer">
            Aus Sicherheitsgründen müssen alle Teilnehmer einer Saunafahrt mindestens 12 Jahre alt sein und schwimmen
            können. Bei einer "Sauna am Steg" gibt es kein Mindestalter.<br/>
            Allerdings müssen Eltern bei Teilnahme von Minderjährigen einen Haftungsausschluss unterzeichnen,
            da das Boot nicht kindersicher ist und eine erhöhte Verletzungsgefahr besteht.
          </div>
        </div>
        <div class="question-section"
             id="fortwo">
          <div class="question">Kann ich ein Erlebnis für 4 Personen auch zu zweit buchen?</div>
          <div class="answer">Ja, das ist kein Problem. 2 Personen sind allerdings bei Fahrten
            die Minimalbesetzung.<br/>
            Der Preis des Erlebnis ändert sich bei weniger Teilnehmern allerdings nicht.
          </div>
        </div>
        <div class="question-section"
             id="spontaneous">
          <div class="question">Wie kann ich kurzfristig (innerhalb der nächsten 2 Tage) buchen?</div>
          <div class="answer">
            Auf der Website können nur Termine mit einer Vorlaufzeit von mindestens 2 Tagen gebucht werden.<br/>
            Solltest Du spontan Lust haben, das Boot zu buchen, so kontaktiere uns telefonisch unter
            {{ $store.getters.configuration.phone }}
            und wir schauen, was sich machen lässt.
          </div>
        </div>
        <div class="question-section"
             id="kaution">
          <div class="question">Muss ich eine Kaution hinterlegen?</div>
          <div class="answer">Ja, bitte bring 200€ in bar mit zum Erlebnis.
            Alle Bedingungen zur Kaution und zur Haftung befinden sich in unseren
            <AGBLink>Allgemeinen Geschäftsbedingungen</AGBLink>
            <br/>
          </div>
        </div>
      </div>
      <div class="category">
        <h2>Das Boot</h2>
        <div class="question-section"
             id="rooms">
          <div class="question">Welche Räumlichkeiten gibt es auf dem Boot?</div>
          <div class="answer">Das Boot verfügt über zwei ca. 6,5qm große Räume.<br/>
            Der eine ist die Sauna, der andere ist der Aufenthalts- und Ruheraum.<br/>
            Im Ruheraum befindet sich neben Sitzgelegenheiten und dem <a href="#fridge">Kühlschrank</a>
            auch eine kleine <a href="#toilets">Toilette</a>.<br/>
          </div>
        </div>
        <div class="question-section" id="toilets">
          <div class="question">Gibt es eine Toilette an Bord?</div>
          <div class="answer">Ja. Es handelt sich um eine Trenntoilette, da es an Bord kein
            fließend Wasser gibt.<br/>
          </div>
        </div>
        <div class="question-section" id="showers">
          <div class="question">Kann man irgendwo Duschen?</div>
          <div class="answer">
            Es gibt nach dem Erlebnis die Möglichkeit, die Umkleiden, Duschen und WC des Seglerstandort am Hafen zu
            verwenden.<br/>
            Diese sind sehr gepflegt und geräumig.
          </div>
        </div>
        <div class="question-section" id="fridge">
          <div class="question">Gibt es (gekühlte) Getränke an Bord?</div>
          <div class="answer">
            Im Ruheraum wird ein gefüllter Kühlschrank mit alkoholischen und nicht-alkoholischen Getränken zu fairen
            Preisen angeboten.<br/>
            Wasser und Tee sind immer inklusive.<br/>
            Solltest Du einen bestimmten Getränkewunsch haben, versuchen wir, diesen zu erfüllen.
          </div>
        </div>
        <div class="question-section" id="driving">
          <div class="question">Was muss ich können, um das Boot zu führen?</div>
          <div class="answer">
            Um das Boot zu führen brauchst Du keinen Führerschein. Die Personen, die das Boot führen
            möchten, erhalten zu Beginn des Erlebnis eine Einweisung in die Führung des Boots.<br/>
            Die Bootsführer müssen in der Lage sein, ein Boot zu führen, wozu körperliche und geistige
            Fitness gehört. So gelten auf dem See die gleichen Alkoholgrenzwerte für Verkehrsteilnehmer wie auf der
            Straße.
          </div>
        </div>
        <div class="question-section" id="anchor">
          <div class="question">Wie läuft das mit dem Ankern?</div>
          <div class="answer">
            Nach der Sauna ist ein Sprung in den See die beste Abkühlung.<br/>
            Es kann jedoch nur von Bord gegangen werden, wenn das Boot verankert ist oder am Steg liegt. Bei der Einweisung erklären wir
            Euch, wie man den Anker auswirft und wieder einholt. Das Wichtigste ist jedoch der richtige Ankerplatz.
            Um den zu finden, haben wir hier eine Karte (Google Maps) erstellt, die Ihr auch während der Fahrt nutzen
            könnt, um bequem und sicher einen Ankerplatz für jeden Wind zu finden.<br/>
            <a href="https://www.google.com/maps/d/viewer?hl=de&hl=de&mid=1IeVnJhTXozNKSAPiir4QIPVKNsJJLZlk&ll=51.23441686064385%2C12.45019716816658&z=14"
               alt="Karte von Ankerplätzen"
               target="anchor_map"
               class="btn btn-primary mt-3">
              Karte mit Ankerplätzen in Google Maps öffnen
            </a>
          </div>
        </div>
      </div>
      <div class="category">
        <h2>Das Erlebnis</h2>
        <div class="question-section" id="script">
          <div class="question">Wie ist der grobe Ablauf?</div>
          <div class="answer">
            Hier gibt es den Ablauf zu jedem Erlebnis:<br>
            <ul>
              <li v-for="product in products" :key="product.id">
                <router-link :to="{name: 'productDetail', params: {productId: product.id}, hash: '#ablauf'}"
                             v-if="product.detailId">
                  {{ product.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="text-center">
            <cloudinary-image
                class="img-fluid mt-2"
                public-id="guides/lounge_2"
                alt="SPABoot Lounge"
                width="640"
                height="328"/>
            <div class="text-muted small mt-0 pt-0">
              Je nach Erlebnis treffen wir uns 15 bzw. 30 Minuten vorher in der SPABoot Lounge (genaue Zeiten kommen vorab per Email).
            </div>
          </div>
        </div>
        <div class="question-section" id="required-belongings">
          <div class="question">Was muss ich mitbringen?</div>
          <div class="answer">
            Du benötigst nur das, was man bei einem normalen Saunagang auch benötigt.<br/>
            Du hast auch die Möglichkeit einen Sauna-Bag von uns zu leihen, der Bademantel, 2 Handtücher
            und Socken beinhaltet.
          </div>
        </div>
      </div>
    </template>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/layouts/StandardLayout";
import CloudinaryImage from "@/components/generic/CloudinaryImage";
import AGBLink from "@/components/AGBLink";

export default {
  data() {
    return {
      products: []
    }
  },
  components: {
    AGBLink,
    CloudinaryImage,
    StandardLayout
  },
  mounted() {
    this.$api.get('/products', {
      params: {
        category: 'RES'
      }
    }).then(response => this.products = response.data)
        .catch(error => this.handleError(error))
  }
}
</script>

<style lang="scss" scoped>
.embedded-image {
  aspect-ratio: attr(width) / attr(height);
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 1.3rem 0 0.3rem 0;
}

.category {
  margin-top: 1.4em;
}

.question-section {
  margin-top: 1.4em;
}

.question-section:target {
  padding-top: 80px;
}

.question {
  font-weight: bold;
}

</style>
